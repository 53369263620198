@tailwind base;
@tailwind components;
@tailwind utilities;

.transition-opacity {
  transition: opacity 500ms ease-in-out;
}


#particles canvas{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#hero{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.vertical-timeline::before {
  background: #5900ff; /* Example: a light purple background for the line */
  width: 4px; /* Makes the line thicker */
}
.vertical-timeline-element-content {
  border-color: #d1c4e9; /* Adjust the border color of the timeline cards */
}



#app {
  overflow:hidden;
}

.vertical-timeline-element-icon {
  background: #673ab7; /* Change the background color of the icons */
}

html {
  scroll-behavior: smooth;
}

@keyframes slideUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.framework-item {
  animation: slideUp 0.5s ease-out;
  
}

@keyframes fadeInGrow {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}


.bubbleUI {
	width: 600px; /* width of BubbleUI container */
	height: 400px; /* height of BubbleUI container */
}

.childComponent {
	width: 100%; /* width expands to fit bubble */
	height: 100%; /* width expands to fit bubble */
	border-radius: 50%; /* rounded border forms a circle */
}